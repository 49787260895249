import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,5,6];

export const dictionary = {
		"/": [~8],
		"/(app)/(protected)/accounts": [~24,[3]],
		"/(app)/(protected)/accounts/[id]": [~25,[3,5]],
		"/(app)/(protected)/accounts/[id]/activity": [~26,[3,5]],
		"/(app)/(protected)/accounts/[id]/connections": [~27,[3,5]],
		"/(app)/(protected)/accounts/[id]/connectors": [~28,[3,5]],
		"/(app)/(protected)/accounts/[id]/projects": [~29,[3,5]],
		"/(app)/(protected)/accounts/[id]/users": [~30,[3,5]],
		"/(app)/(protected)/activity": [~31,[3]],
		"/(app)/(auth)/auth/check_email": [9,[2]],
		"/(app)/(auth)/auth/reset_password": [~10,[2]],
		"/(app)/(auth)/auth/token_hash_error": [11,[2]],
		"/(app)/(auth)/auth/update_password": [~12,[2]],
		"/(app)/(protected)/connections": [~32,[3]],
		"/(app)/(protected)/connections/[id]": [33,[3]],
		"/(app)/(auth)/login": [~13,[2]],
		"/(app)/(auth)/login/sso": [~14,[2]],
		"/(app)/(protected)/oauth/authorization": [~34,[3]],
		"/(app)/(protected)/oauth/callback": [~35,[3]],
		"/(app)/(protected)/profile": [~36,[3]],
		"/(app)/(protected)/projects": [37,[3]],
		"/(app)/(protected)/projects/[id]": [~38,[3]],
		"/(app)/(protected)/projects/[id]/workflows/[workflowId]": [39,[3,6,7]],
		"/(app)/(auth)/register": [~15,[2]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/connect": [16,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/connect/[id]": [~17,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/done": [18,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/processor": [~19,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/processor/[id]": [~20,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/resources/[id]": [~21,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/settings": [~22,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/[[project_id]]/workflow": [23,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';